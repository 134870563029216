import React from "react";

import pageStyle from "../assets/css/page.module.css";
import style from "../assets/css/main.module.css";
import Menu from "../components/menu";
import Footer from "../components/footer";
import HeroPage from "../components/hero.page";
import Hamburger from "../components/hamburger";

import imgHero from "../assets/img/heroes/vie_privee.jpg";
import Cookie from "../components/cookie";
import SEO from "../components/seo";

class Cookies extends React.Component {


  render() {
    return (
      <div id="top">
        <Menu />
        <SEO
        title="Préférences cookies"
        description="Sauf pour les cookies et technologies similaires « Cookies » qui sont strictement nécessaires au fonctionnement du site internet, vous pouvez accepter ou refuser que nous utilisions des Cookies ci-dessous."
        keywords="cookies"
        />
        <div className={style.menu_mobile}>
          <Hamburger />
        </div>
        <HeroPage img={imgHero} />

        <article
          className={pageStyle.article}
          style={{ paddingBottom: "50px" }}
        >
          <h2>Préférences de cookies</h2>
          <p>
            Sauf pour les cookies et technologies similaires &laquo; Cookies &raquo; qui
            sont strictement nécessaires au fonctionnement du site internet,
            vous pouvez accepter ou refuser que nous utilisions des Cookies pour
            chaque finalité distincte ci-dessous mentionnée, en déplaçant le
            curseur à la fin de chaque ligne. Chaque fois qu’il vous est donné
            la possibilité d’accepter ou de refuser l’utilisation de Cookies,
            nous vous fournissons les informations essentielles afin de vous
            permettre de faire votre choix : l’objectif du traitement, le Cookie
            ou la catégorie de Cookies utilisé(s), l’identité du responsable de
            traitement, les types de données personnelles recueillies et
            utilisées et le transfert éventuel de vos données personnelles hors
            de l’Espace Economique Européen. Une fois que vous nous avez donné
            votre accord concernant l’utilisation de Cookies pour une finalité,
            vous pouvez revenir sur votre choix, à tout moment, en déplaçant le
            curseur à la fin de la ligne concernée.
          </p>
<div className={pageStyle.cookie_settings}>
<h4 style={{fontSize: '1.4em', textAlign: 'left'}}>
              
              Cookies strictement nécessaires
              
 </h4>
 <div className={pageStyle.cookie_toggle}>Toujours actif</div>
</div>


          <p>
          Ces cookies sont indispensables au bon fonctionnement du site web et ne peuvent pas être désactivés de nos systèmes. Ils ne sont généralement qu’activés en réponse à des actions que vous effectuez et qui correspondent à une demande de services, comme la configuration de vos préférences de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur pour bloquer ou être alerté de l’utilisation de ces cookies. Cependant, si cette catégorie de cookies – qui ne stocke aucune donnée personnelle est bloquée, certaines parties du site ne pourront pas fonctionner.
          </p>
<div className={pageStyle.cookie_settings}>
<h4 style={{fontSize: '1.4em', textAlign: 'left'}}>
              
Cookies de performance
              
 </h4>
 <div className={pageStyle.cookie_toggle}><Cookie></Cookie></div>
</div>


          <p>
          Ces cookies sont indispensables au bon fonctionnement du site web et ne peuvent pas être désactivés de nos systèmes. Ils ne sont généralement qu’activés en réponse à des actions que vous effectuez et qui correspondent à une demande de services, comme la configuration de vos préférences de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur pour bloquer ou être alerté de l’utilisation de ces cookies. Cependant, si cette catégorie de cookies – qui ne stocke aucune donnée personnelle est bloquée, certaines parties du site ne pourront pas fonctionner.
          </p>

          
        </article>

        <Footer />
      </div>
    );
  }


}

export default Cookies;
