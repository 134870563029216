import React from 'react';

import pageStyle from '../assets/css/page.module.css';

class Cookie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookiesEnabled: false
        }
        this.getMyCookie = this.getMyCookie.bind(this);
        this.getCa = this.getCa.bind(this)
        this.enableCookies = this.enableCookies.bind(this)
        this.disableCookies = this.disableCookies.bind(this)
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getMyCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getCa() {
        return this.getMyCookie('caccepted')
    }

    enableCookies() {
        if (this.getCa() === 'false') {
            this.setState({ cookiesEnabled: true })
            this.setCookie('caccepted', 'true', 365)
            this.setCookie('cpolicy', 'true', 365)
        }else{
            this.setState({ cookiesEnabled: true})
        }
    }
    disableCookies() {
        if (this.getCa() === 'true') {
            this.setState({ cookiesEnabled: false })
            this.setCookie('caccepted', 'false', 365)
            this.setCookie('cpolicy', 'true', 365)
        }else{
            this.setState({ cookiesEnabled: false})
        }
    }

    

    render() {
        return (
            <div>
                <span onClick={this.enableCookies} className={ this.state.cookiesEnabled ? pageStyle.cookie_enabled : pageStyle.cookie_disabled}>Actif</span> | <span onClick={this.disableCookies} className={ this.state.cookiesEnabled ? pageStyle.cookie_disabled : pageStyle.cookie_enabled}>Inactif</span>
            </div>
        )
    }
}

export default Cookie;